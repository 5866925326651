
<template>
  <v-dialog
    v-model="showing_modal"
    width="auto"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        ¿Quieres cambiar el estado de este canal?
      </v-card-title>

      <v-card-text>
        El canal '{{ channel.name }}' pasará al estado '{{ channel.enabled? 'No activo' : 'Activo' }}'
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
          CANCELAR
        </v-btn>
        <v-btn color="primary" @click="toggleChannelStatus()">
          CONFIRMAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
  export default {
    data: () => {
      return {
        showing_modal: false,
        channel: {},
      }
    },
    mounted() {
    },
    methods: {
      open(item) {
        // if (item) this.setItemToEdit(item)
        console.log(item)
        this.channel = item
        this.showing_modal = true
      },

      close() {
        this.showing_modal = false
      },

      async toggleChannelStatus() {
        try {
          await this.$api.toggleStatus(this.channel.id)
          this.$emit('reload-channel-list')
          this.close()
        } catch (error) {
          console.log(error)
        }
      },

    },
    computed: {
    },
  }
  </script>
  