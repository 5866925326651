<template>
  <v-dialog
  v-model="showing_modal"
  width="900">
    <v-card>
      <v-card-text>
        <div class="d-flex align-center flex-wrap pb-0">
          <v-spacer></v-spacer>
          <div class="d-flex align-center flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              @click="createVcfFile()"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Generar nuevo VCF</span>
            </v-btn>
          </div>
        </div>
        <v-divider></v-divider>
        <v-data-table
          :headers="tableColumns"
          :items="vcfFilesListTable"
          :page="current_page"
          :server-items-length="totalVcfFilesListTable"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
          @update:page="changingVcfFilesPage($event)"
          @update:items-per-page="changingVcfFilesItemsPerPage($event)"
        >
          <!-- NO data -->
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                No hay
                <strong>archivos VCF</strong>
                disponibles para esta búsqueda
              </v-list-item-title>
            </v-list-item>
          </template>

          <!-- Name -->
          <template #[`item.name`]="{ item }">
            <span class="date-container">{{ item.name }}</span>
          </template>
          <!-- Phone -->

          <!--Date -->
          <template #[`item.created_at`]="{ item }">
            <span class="date-container">{{ item.created_at }}</span>
          </template>

          <!--Status-->
          <template #[`item.status`]="{item}">
            <div class="d-flex justify-center">
              <v-chip
              small
              :color="item.status === 1 ? 'success' : item.status === 0 ? 'info' : 'error'"
              :class="`${item.status === 1 ? 'success' : item.status === 0 ? 'info' : 'error'}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.status === 1 ? 'Completado' : item.status === 0 ? 'En creación' : 'Fallido' }}
            </v-chip>
            </div>
          </template>

          <!--Download -->
          <template #[`item.download`]="{ item }">
            <v-btn icon :disabled="item.status !== 1" @click="clickCreatedLink(item.file, item.name)">
              <v-icon class="success--text">
                {{ icons.mdiFileDownloadOutline }}
              </v-icon>
            </v-btn>
          </template>

          <!--Delete -->
          <template #[`item.delete`]="{ item }">
            <v-btn icon @click="$refs.delete_vcf_modal.open(item)">
              <v-icon class="error--text">
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close()">
            CERRAR
            </v-btn>
        </v-card-actions>

        <DeleteVCFModal
          ref="delete_vcf_modal"
          @reload-vcf-list="getVcfFiles"
        />
    </v-card>
    <alert-pop-up ref="alert_pop_up"/>
  </v-dialog>
</template>

<script>
import DeleteVCFModal from '@/components/Channels/DeleteVCFModal.vue'
import AlertPopUp from '@/components/AlertPopUp.vue';

import {
  mdiPlus,
  mdiFileDownloadOutline,
  mdiDeleteOutline
} from "@mdi/js";

export default {
  data: () => {
    return {
      showing_modal: false,
      loading: false,
      vcfFiles: [],
      totalVcfFilesListTable: 0,
      vcfFilesListTable: [],
      current_page: 1,
      vcfFilters: {},
      page_size: 10,
      timer: null
    }
  },
  components : {
    DeleteVCFModal,
    AlertPopUp,
  },
  methods: {
    async open(filters) {
      this.vcfFilters = filters
      this.getVcfFiles()
      this.showing_modal = true
    },

    async getVcfFiles() {
      this.loading = true
      try {
        const form = {
          company_id: this.getSelectedCompany,
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
        }
        const response = await this.$api.getVcfFiles(form, this.vcfFilters)

        this.vcfFilesListTable = response.results
        this.totalVcfFilesListTable = response.count

        this.getVcfCreatingFlag()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async getVcfCreatingFlag() {
      const response = await this.$api.getVcfCreatingFlag()
      console.log(response)
      if(response || !this.timer) {
        this.setTimer()
      }

      if(!response) {
        clearTimeout(this.timer)
      }
    },

    setTimer() {

      this.timer = setTimeout(() => {
        // console.log(this.campaign_id)
        this.getVcfFiles();
      }, 1000 * 10);
    },

    clickCreatedLink(data, name) {
      //const blob = new Blob([data], { type: 'text/html;charset=utf-8;' });
      //const url = URL.createObjectURL(blob)
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", name);
      link.click();
    },

    changingVcfFilesPage(e) {
      this.current_page = e;
      this.getVcfFiles()
    },

    changingVcfFilesItemsPerPage(e) {
      this.page_size = e;
      this.getVcfFiles()
    },

    async createVcfFile() {
      try {
        let form = {
          company: this.getSelectedCompany
        }
        if(this.vcfFilters.enabled !== -1) {
          form.enabled = this.vcfFilters.enabled === 0 ? true : false
        }

        if(this.vcfFilters.status !== -1) {
          form.status = this.vcfFilters.status === 0 ? false : true
        }
        const response = await this.$api.createVcfFile(form)
        this.$refs.alert_pop_up.showPopUp({ message: response.data, type: 'success' })
        this.getVcfFiles()
      } catch(e) {
        console.log(e)
      }
    },

    close() {
      this.vcfFilesListTable = []
      this.totalVcfFilesListTable = 0
      clearTimeout(this.timer);
      this.showing_modal = false
    },
  },

  computed: {
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },

  setup() {
    const tableColumns = [
      { text: "Nombre", value: "name", align: "center", sortable: false },
      { text: "Fecha de creación", value: "created_at", align: "center", sortable: false },
      { text: "Estado", value: "status", align: "center", sortable: false },
      { text: "Descargar", value: "download", align: "center", sortable: false },
      { text: "Eliminar", value: "delete", align: "center", sortable: false },
    ]

    return {
				tableColumns,
				icons: {
					mdiPlus,
          mdiFileDownloadOutline,
          mdiDeleteOutline
				}
			}
  }
}
</script>

<style scoped>
.date-container {
  font-weight: 500;
  color: rgb(125, 125, 125);
}
</style>