<template>
  <v-dialog v-model="showingChannelHistory">
    <v-card>
      <v-data-table
      :headers="tableColumns"
      :items="numbers_history"
      :page="current_page"
      :loading="loading"
      :server-items-length="totalListTable"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @pagination="changingMsgPage($event)"
      >
        <template  v-slot:no-data>
          Historial vacío
        </template>

        <template #[`item.number`]="{item}">
          <div>
            {{ item.number }}
          </div>
        </template>

        <template #[`item.created_at`]="{item}">
          <div>
            {{ item.created_at }}
          </div>
        </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close()">
        CERRAR
      </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  data: () => {
    return {
      numbers_history: [],
      current_page: 1,
      showingChannelHistory: false,
      page_size: 10,
      channel_id: null,
    }
  },
  methods: {
    async getChannelNumbersHistory() {
      try {
        const data = {
          page_size: this.page_size,
          page: this.current_page,
        }
        const item = await this.$api.getChannelNumbersHistory(this.channel_id, data)
        this.numbers_history = item.results
      
        this.loading = false
        this.totalListTable = item.count
        this.showingChannelHistory = true
      } catch (e) {
        console.log(e);
      }
    },
    async openNumbersHistoryModal(id) {
        // console.log(item)
      this.loading = true
      this.channel_id = id
      this.getChannelNumbersHistory()
    },
    changingMsgPage(e) {
      if (e.pageStop !== 0) {
        // console.log(e)
        this.current_page = e.page
        this.page_size = e.itemsPerPage
        this.getChannelNumbersHistory()
    }
  },
  close(){
    this.channel_id = null
    this.current_page = 1
    this.page_size = 10
    this.numbers_history = []
    this.totalListTable = 0
    this.showingChannelHistory = false
  }
  },
  setup() {
      const loading = ref(false)
      const totalListTable = ref(0)

      const tableColumns = [
        { text: 'Número', value: 'number', align: 'center', sortable: false},
        { text: 'Fecha de alta', value: 'created_at', align: 'center', sortable: false },
      ]

      return {
        loading,
        totalListTable,
        tableColumns,
      }
    }
}
</script>