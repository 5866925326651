<template>
    <div>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Canales
      </v-card-title>

      <v-col cols="12" v-if="!getSelectedCompany">
        <v-alert
          dense
          border="left"
          color:
          type="warning"
        >
          No hay empresa <strong>seleccionada</strong>
        </v-alert>
      </v-col>


      <v-divider class="mt-4" v-if="getSelectedCompany"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          @keyup="handleSearch"
          placeholder="Buscar por nombre de canal"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>
        <v-select
          style="width: 0px"
          class="mb-4 mr-2"
          :items="[{name: '-', value: -1}, {name: 'Activo', value: 0}, {name: 'No activo', value: 1}]"
          hide-details="true"
          v-model="enabledFilterValue"
          label="Activo"
          item-text="name"
          item-value="value"
          @change="setFilter"
          outlined
          dense
        ></v-select>

        <v-select
          style="width: 0px"
          class="mb-4 mr-2"
          :items="[{name: '-', value: -1}, {name: 'Funciona', value: 1}, {name: 'No funciona', value: 0}]"
          hide-details="true"
          v-model="statusFilterValue"
          label="Estado"
          item-text="name"
          item-value="value"
          @change="setFilter"
          outlined
          dense
        ></v-select>


        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-1"
            v-if="getUserRole === 0"
            @click="$refs.channel_form_modal.open()"
          >
            <!-- @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive" -->
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Canal</span>
          </v-btn>

        </div>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="info"
            class="mb-4 me-1"
            size="small"
            v-if="getUserRole === 0"
            @click="$refs.files_vcf_list.open({enabled: enabledFilterValue,status: statusFilterValue})"
          >
            <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
            <span>Ver archivos VCF</span>
          </v-btn>

        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :page="current_page"
        :items="channelListTable"
        :options.sync="options"
        :server-items-length="totalChannelListTable"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @update:page="changingChannelsPage($event)"
        @update:items-per-page="changingChannelsItemsPerPage($event)"
      >
        <!-- show-select -->

        <!-- no-data -->
        <template  v-slot:no-data>
          {{
            getSelectedCompany
              ? searchQuery.length? 'No hay canales que coincidan con la búsqueda' : 'Aún no hay canales'
              : 'No hay empresa seleccionada. Seleccione una para ver los canales asociados'
          }}
        </template>

        <!-- refresh -->
        <!-- <template #[`item.refresh`]="{item}">
          <button
            class="mr-3"
            @click="checkStatusChannel(item.id)"
            >
              <v-icon
              :color="'primary'"
              :disabled="item.refreshing"
              >
                {{icons.mdiReloadAlert}}
              </v-icon>
            </button>
        </template> -->

        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex justify-center">
            <v-btn
              class="channel-btn"
              text
              @click="$refs.channel_info_modal.open(item)"
            >
              {{ item.name }}
            </v-btn>
  
            <v-tooltip v-if="item.in_breakdown" bottom color="primary" open-delay="300">
              <template v-slot:activator="{ on, attrs }">
                <button
                v-on="on"
                v-bind="attrs"
                class="mr-3"
                
                >
                  <v-icon
                  :color="'primary'"
                  >
                    {{icons.mdiSleep}}
                  </v-icon>
                </button>
              </template>
              <span>Descansando desde {{ item.campaign_block ? item.campaign_block.start_break_date.replace('T', ' ').replace('Z', '') : ''}} hasta {{ item.campaign_block ? item.campaign_block.end_break_date.replace('T', ' ').replace('Z', '') : '' }}</span>
            </v-tooltip>

          </div>
        </template>

        <!-- enable -->
        <template #[`item.enabled`]="{item}">
          <v-tooltip right open-delay="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" icon @click="$refs.toggle_channel_status_modal.open(item)">
                <v-icon :class="`${resolveChannelStatusVariant(item.enabled)}--text`">
                  {{ + item.enabled? icons.mdiCheckCircleOutline : icons.mdiCloseCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Activar/Desactivar canal </span>
          </v-tooltip>

        </template>

        <template v-slot:header.status="{ header }">
          <div class="text-center">{{ 'ESTADO' }}</div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <div class="d-flex justify-center">
            <v-tooltip bottom color="primary" open-delay="300">
              <template v-slot:activator="{ on, attrs }">
                <button
                v-on="on"
                v-bind="attrs"
                class="mr-3"
                @click="checkStatusChannel(item.id)"
                >
                  <v-icon
                  :color="'primary'"
                  :disabled="item.refreshing"
                  >
                    {{icons.mdiReloadAlert}}
                  </v-icon>
                </button>
              </template>
              <span>Actualizar estado de Ultramsg</span>
            </v-tooltip>
            <v-chip
              small
              :color="resolveChannelStatusVariant(item.status)"
              :class="`${resolveChannelStatusVariant(item.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.status? 'FUNCIONA' : 'NO FUNCIONA' }}
            </v-chip>
            <v-tooltip color="rgb(0,0,0,0.9)" right v-if="!item.status && item.instance_status !== 'authenticated'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-2"
                    color="error"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{setPopupMessage(item.instance_status)}}</span>
            </v-tooltip>
          </div>
        </template>

        <!-- number -->
        <template #[`item.number`]="{item}">
          <div class="d-flex justify-space-evenly align-end">
            <v-tooltip bottom color="accent" open-delay="300">
              <template v-slot:activator="{ on, attrs }">
                <button
                v-on="on"
                v-bind="attrs"
                class="mr-3"
                @click="getUltraSettings(item)"
                >
                  <v-icon
                  :color="'accent'"
                  :disabled="item.refreshing"
                  >
                    {{icons.mdiReloadAlert}}
                  </v-icon>
                </button>
              </template>
              <span>Actualizar numero y configuración de Ultramsg</span>
            </v-tooltip>
            <div>
              {{ item.number? item.number : '- Sin numero' }}
            </div>
            <v-tooltip bottom color="accent" open-delay="300">
              <template v-slot:activator="{ on, attrs }">
                <button
                v-on="on"
                v-bind="attrs"
                class="ml-3"
                @click="$refs.channel_numbers_history_modal.openNumbersHistoryModal(item.id)"
                >
                  <v-icon
                  :color="'primary'"
                  :disabled="item.refreshing"
                  >
                    {{icons.mdiClipboardClockOutline}}
                  </v-icon>
                </button>
              </template>
              <span>Historial</span>
            </v-tooltip>
          </div>
        </template>

        <!-- messages -->
        <template #[`item.messages`]="{item}">
          <div> Max.:  <b>{{ item.max_msg_daily? item.max_msg_daily : '-' }}</b></div>
          <div> Enviados hoy:  <b>{{ item.messages_sent_today? item.messages_sent_today : '-' }}</b></div>
        </template>

        <!-- datetime sync -->
        <template #[`item.respond_last_sinc`]="{item}">
          <div><b>Ultramsg: </b>{{ item.last_reset? item.last_reset /* parseDatetime(respond_last_sinc) */ : '-' }}</div>
          <div><b>Respond: </b> {{ item.respond_last_sinc? item.respond_last_sinc /* parseDatetime(respond_last_sinc) */ : '-' }}</div>
        </template>

        <!-- edit -->
        <template #[`item.edit`]="{item}">
          <v-btn
            small
            icon
            color="primary"
            @click="$refs.channel_form_modal.open(item)"
          >
            <v-icon>
              {{ icons.mdiSquareEditOutline }}
            </v-icon>
          </v-btn>

          <v-btn
            small
            icon
            @click="$refs.delete_channel_modal.open(item)"
          >
            <v-icon :class="`error--text`">
              {{ item.enabled? icons.mdiDeleteOutline : icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>

        <!-- delete -->
        <!-- <template #[`item.delete`]="{item}">
          <v-btn
            icon
            @click="$refs.delete_channel_modal.open(item)"
          >
            <v-icon :class="`error--text`">
              {{ item.enabled? icons.mdiDeleteOutline : icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template> -->

      </v-data-table>

      <toggle-status-modal
        ref="toggle_channel_status_modal"
        @reload-channel-list="getChannels()"
      />

      <channel-numbers-history-modal
        ref="channel_numbers_history_modal"
      />

      <channel-form-modal
        ref="channel_form_modal"
        @reload-channel-list="getChannels()"
      ></channel-form-modal>

      <channel-info-modal
        ref="channel_info_modal"
        @reload-channel-list="getChannels()"
      ></channel-info-modal>

      <delete-channel-modal
        ref="delete_channel_modal"
        @reload-channel-list="getChannels()"
      ></delete-channel-modal>

      <FilesVCFList
      ref="files_vcf_list"
      />

    </v-card>

    <alert-pop-up ref="alert_pop_up"/>
    </div>
</template>

<script>
const debounce = require('debounce');

// eslint-disable-next-line object-curly-newline
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiReloadAlert,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiSquareEditOutline,
  mdiInformationOutline,
  mdiClipboardClockOutline,
  mdiSleep,
  mdiEyeOutline
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import AlertPopUp from '@/components/AlertPopUp.vue';
import useUsersList from '../Messages/useUsersList'
import ChannelFormModal from './ChannelFormModal.vue'
import DeleteChannelModal from './DeleteChannelModal.vue'
import ToggleStatusModal from './ToggleStatusModal.vue'
import ChannelInfoModal from './ChannelInfoModal.vue';
import ChannelNumbersHistoryModal from './ChannelNumbersHistoryModal.vue';
import FilesVCFList from '@/components/Channels/FilesVCFList.vue';

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      enabled_toggle_confirm_modal: false,
      statusFilterValue: -1,
      enabledFilterValue: -1,
      settingsErrorMsg: null,
      showingHistoryModal: false,
      timer: null
    }
  },
  mounted() {
    if (this.getSelectedCompany) {
      this.loading = true
      this.getChannels()
    }
  },
  methods: {

    setPopupMessage(status) {
      if(status === 'disconnected') {
        return 'Desconectado: La sesión finalizó. Se debe iniciar sesión nuevamente.'
      } else if(status === 'qr') {
        return 'Código QR disponible para escanear y autenticarse.'
      } else if(status === 'retrying') {
        return 'Reintentando: Debe volver a escanear el código QR.'
      } else if (status === 'loading') {
        return 'Cargando: El código QR se está cargando.'
      } else if (status === 'initialize') {
        return 'Inicializando: La instancia se encuentra en su primer estado, aguarde para escanear código QR.'
      }else {
        return 'Modo de espera: Debe escanear código QR.'
      }
    },
    // parseDatetime(date) {
    //   const dateObject = new Date(date)
    //   const stringDate = dateObject.toLocaleDateString('es-AR', this.dateOptions)
    //   const capitalized = stringDate.charAt(0).toUpperCase() + stringDate.slice(1)
    //   return capitalized
    // },

    setFilter() {
      this.current_page = 1
      this.getChannels()
    },
    handleSearch: debounce(function (e) {
      // console.log(this.searchQuery)
      this.current_page = 1
      this.getChannels()
    }, 400),

    changingChannelsPage(e) {
      this.current_page = e
      this.getChannels()
    },

    changingChannelsItemsPerPage(e) {
      this.page_size = e
      this.getChannels()
    },

    resetTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.getChannels()
      }, 1000 * 60)
    },

    async getChannels() {
      this.loading = true

      const form = {
        company_id: this.getSelectedCompany,
        page_size: this.page_size,
        page: this.current_page,
        search: this.searchQuery,
      }
      const filters = {
        enabled: this.enabledFilterValue,
        status: this.statusFilterValue
      }

      try {
        const response = await this.$api.getChannels(form, filters)

        this.channelListTable = response.results
        this.totalChannelListTable = response.count
      } catch (error) {

      } finally {
        this.loading = false
        this.resetTimer()
      }
    },
    async checkStatusChannel(channelId) {
      this.loading = true;
      const index = this.channelListTable.findIndex(channel => channel.id === channelId);
      this.channelListTable[index].refreshing = true;

      try {
        const response = await this.$api.updateStatusChannel(channelId)
        this.getChannels()
        this.$refs.alert_pop_up.showPopUp({ message: response.data.message, type: 'success' })

      } catch (error) {
        console.log(error.response)
        const errorMessage = error.response.data[0] || "Ha ocurrido un error";

        if (errorMessage.includes("Ocurrió un error en la petición a Ultramsg")) {

          const formattedErrorMessage = errorMessage.split(":")[0];
          this.$refs.alert_pop_up.showPopUp({ message: formattedErrorMessage, type: 'error' })

        } else {
          this.$refs.alert_pop_up.showPopUp({ message: errorMessage, type: 'error' })
        }
        this.loading = false
      } finally {
        this.channelListTable[index].refreshing = false;
        this.loading = false; /* this.loadIcon = false; */
      }
    },
    async getUltraSettings(channel) {

      this.loading = true

      try {
        const response = await this.$api.updateUltraSettings(channel.id)

        this.getChannels()
        this.$refs.alert_pop_up.showPopUp({ message: response.message, type: 'success' })
        this.loading = false
      } catch (error) {
        const errorMessage = error.response.data[0] || "Ha ocurrido un error";

        if (errorMessage.includes("Ocurrió un error en la petición a Ultramsg")) {

          const formattedErrorMessage = errorMessage.split(":")[0];
          this.$refs.alert_pop_up.showPopUp({ message: formattedErrorMessage, type: 'error' })

        } else {
          this.$refs.alert_pop_up.showPopUp({ message: errorMessage, type: 'error' })
        }
        this.loading = false
      }

    },
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  computed: {
    dateOptions() { return {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", hour12:"false", minute: "numeric", second: "numeric"}},
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  watch: {
    getSelectedCompany(newValue) {
      // console.log('getSelectedCompany');
      if (newValue) {
        this.getChannels()
      }
    }
  },
  components: {
    AlertPopUp,
    ChannelFormModal,
    DeleteChannelModal,
    ToggleStatusModal,
    ChannelInfoModal,
    ChannelNumbersHistoryModal,
    FilesVCFList
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      userTotalLocal,
      selectedRows,

      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
    } = useUsersList()

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]



    // New configurations

    const totalChannelListTable = ref(0)

    const tableColumns = [
      { text: 'CANAL', value: 'name', align: 'center', sortable: false },
      // { text: 'ACTUALIZAR', value: 'refresh', align: 'center', sortable: false, },
      { text: 'ACTIVO', value: 'enabled', align: 'center', sortable: false, width: "40px"  },
      { text: 'ESTADO', value: 'status', align: 'left', sortable: false, width: "220px"  },
      { text: 'TEL. ASOCIADO', value: 'number', align: 'center', sortable: false, width: "200px"  },
      { text: 'MENSAJES', value: 'messages', align: 'left', sortable: false },
      { text: 'ACTUALIZACIONES', value: 'respond_last_sinc', align: 'left', sortable: false },
      { text: 'EDITAR', value: 'edit', align: 'center', sortable: false, width: "100px" },
      // { text: 'ELIMINAR', value: 'delete', align: 'center', sortable: false },
    ]

    const loading = ref(false)

    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })

    const channelListTable = ref([])

    const resolveChannelStatusVariant = status => {
      if (status === 'FAILED') return 'error'
      if (status === 'SENDED') return 'success'
      if (status === 'PENDING') return 'primary'
      if (status === true) return 'success'
      if (status === false || status === null) return 'error'

      return 'primary'
    }


    // New configurations


    return {
      channelListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      totalChannelListTable,
      roleOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveChannelStatusVariant,
      resolveUserTotalIcon,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiReloadAlert,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiSquareEditOutline,
        mdiInformationOutline,
        mdiClipboardClockOutline,
        mdiSleep,
        mdiEyeOutline
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
