<template>
  <v-dialog
    v-model="showingDeleteVcfModal"
    width="auto"
  >
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        ¿Estás seguro de eliminar el archivo '{{ vcfFile.name }}'?
      </v-card-title>

      <v-divider></v-divider>

      <v-card-actions>
        <span 
        class="error--text error-msg"
        v-if="error_msg"
        >{{ error_msg }}</span>
        <v-spacer></v-spacer>

        <v-btn 
        color="primary" 
        text 
        @click="close()"
        >
          CANCELAR
        </v-btn>         
        <v-btn
        color="error"
        @click="deleteVcfFile()"
        >
          Eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => {
      return {
          showingDeleteVcfModal: false,
          vcfFile: {},
          error_msg: null
      }
  },

  methods: {
      open(vcf) {
        this.showingDeleteVcfModal = true
        this.vcfFile = vcf
      },

      close() {
        this.error_msg = null
        this.showingDeleteVcfModal = false
      },

      async deleteVcfFile() {
          try {
              const response = await this.$api.deleteVcfFile(this.vcfFile.id)

              this.$emit('reload-vcf-list')
              this.close()
          } catch(e) {
              console.log(e)
              if (e?.response?.status === 403) {
                console.log(e.response.data)
                this.error_msg = e?.response?.data?.message
              }
          }
      }
  }
}
</script>

<style scoped>
.error_msg{
    color: red;
    margin-left: 25px;
}
</style>