
<template>
  <v-dialog
      v-model="showingDeleteChannelModal"
      width="auto"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          ¿Estás seguro de eliminar el canal '{{ channel.name }}'?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            text 
            @click="close()"
          >
            CANCELAR
          </v-btn>         
          <v-btn
            color="error"
            @click="deleteChannel()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
  
  <script>
  
  export default {

    methods: {
      open(channel) {
        this.showingDeleteChannelModal = true
        this.channel = channel
      },

      close() {
        this.showingDeleteChannelModal = false
      },


      async deleteChannel() {
        try {
          const response = await this.$api.deleteChannel(this.channel.id)
          // console.log(response);
          this.$emit('reload-channel-list')
          this.close()
        } catch (e) {
          console.log(e)
        }
      },

    },
    data: () => {
      return {
        showingDeleteChannelModal: false,
        channel: {},
      }
    },
    computed: {
    },
  }
  </script>
  