import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"auto"},model:{value:(_vm.showing_modal),callback:function ($$v) {_vm.showing_modal=$$v},expression:"showing_modal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" ¿Quieres cambiar el estado de este canal? ")]),_c(VCardText,[_vm._v(" El canal '"+_vm._s(_vm.channel.name)+"' pasará al estado '"+_vm._s(_vm.channel.enabled? 'No activo' : 'Activo')+"' ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleChannelStatus()}}},[_vm._v(" CONFIRMAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }