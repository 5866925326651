
<template>
  <v-dialog v-model="showingChannelModal" width="700" scrollable persistent>
    <v-form ref="channelForm" @submit.prevent="createChannel">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ editForm ? 'Editar Canal' : 'Crear canal'}}
        </v-card-title>

        <v-card-text style="max-height: 90%">


            <v-row class="mt-2">

              <v-col cols="12" md="12">
                <v-text-field
                  ref="name"
                  v-model="channel_form.name"
                  :rules="[v => !!v || 'Campo requerido']"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details
                  label="Nombre de la instancia"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <!-- :rules="[v => !!v || 'Campo requerido']"  -->
                <v-text-field
                  ref="description"
                  v-model="channel_form.description"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details
                  label="Descripción del canal"
                ></v-text-field>
              </v-col>

              <v-col cols="8" md="8">
                <v-text-field
                ref="max_msg_daily"
                v-model="channel_form.max_msg_daily"
                :rules="[v => !!v || 'Campo requerido']"
                outlined
                dense
                type="number"
                autocomplete="off"
                hide-details
                label="Máximo de mensajes por día"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" v-if="getUserRole === 0">
                <v-autocomplete
                  name="companySelector"
                  v-model="selectedCompany"
                  :items="getAllCompaniesList"
                  :search-input="search"
                  :filter="filter"
                  label="Elige una o varias empresas para vincular"
                  persistent-hint
                  hint="Puedes buscar un empresa escribiendo su nombre"
                  outlined
                  dense
                  multiple
                >

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      :input-value="selected"
                      label
                    >
                      <span class="pr-2">
                        {{ item.name }}
                      </span>
                      <v-icon
                        @click="parent.selectItem(item)"
                        small
                      >
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-chip
                      label
                    >
                      {{ item.name }}
                    </v-chip>
                  </template>

                </v-autocomplete>
              </v-col>

              <v-checkbox
                v-model="channel_form.campaign_message"
                class="px-3"
                hide-details
                label="Usar canal para envío de mensajes de campaña"
              ></v-checkbox>

                <v-checkbox
                v-model="channel_form.manual_message"
                class="px-3 my-4"
                hide-details
                label="Usar canal para envío de mensajes manuales"
              ></v-checkbox>


              <v-col cols="12" md="12">

                <v-expansion-panels multiple :value="panelUltra" focusable>
                  <v-expansion-panel  >
                    <v-expansion-panel-header>Ultramsg</v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-text-field
                          ref="api_url"
                          v-model="channel_form.api_url"
                          :rules="[v => !!v || 'Campo requerido']"
                          outlined
                          dense
                          hint="Formato: 'https://api.ultramsg.com/instanceXXXX/'"
                          persistent-hint
                          autocomplete="off"
                          label="API-URL"
                        ></v-text-field>


                        <v-text-field
                          ref="token"
                          v-model="channel_form.token"
                          :rules="[v => !!v || 'Campo requerido']"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details
                          label="Token"
                        ></v-text-field>

                        <v-col cols="12" class="d-flex justify-space-between">
                          <v-checkbox
                            v-model="isUltraSettings"
                            hide-details
                            @input="checkSettings"
                            label="Configuración de Ultramsg"
                          >
                            <!-- <template v-slot:label>
                              <div>
                                <span>Configuración de Ultra</span>

                              </div>
                            </template> -->
                          </v-checkbox>
                          <v-tooltip bottom color="accent" open-delay="300" v-if="Object.keys(ultra_settings).length">
                            <template v-slot:activator="{ on, attrs }">
                              <button @click.prevent="getUltraSettings"
                              v-on="on"
                              v-bind="attrs"
                              class="mr-3 pt-4"
                              @click="getUltraSettings"
                              >
                                <v-icon
                                :color="'accent'"
                                >
                                  {{icons.mdiReloadAlert}}
                                </v-icon>
                              </button>
                            </template>
                            <span>Actualizar numero y configuración de Ultramsg</span>
                          </v-tooltip>
                        </v-col>

                        <div v-if="Object.keys(ultra_settings).length">
                          <v-col cols="12" v-if="isUltraSettings && !settingsLoaded">
                            <v-skeleton-loader
                              v-bind="{
                                boilerplate: false,
                                elevation: 0,
                              }"
                              type="article, list-item-three-line"
                            ></v-skeleton-loader>
                          </v-col>
                          <v-row class="mt-3" v-if="isUltraSettings && settingsLoaded">
                            <v-col cols="12">
                              <v-text-field
                                ref="settings_url"
                                v-model="ultra_settings.webhook_url"
                                outlined
                                dense
                                autocomplete="off"
                                hide-details
                                label="webhook_url"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" class="d-flex">
                              <v-text-field style="width: 40%;"
                                ref="settings_number"
                                v-model="asociated_number"
                                outlined
                                dense
                                readonly
                                autocomplete="off"
                                hide-details
                                label="Número asociado"
                              ></v-text-field>
                              <v-checkbox
                                ref="belongs_blacklist"
                                v-model="belongs_blacklist"
                                class="ps-3 pe-12 mt-1"
                                hide-details
                                label="Blacklist"
                              ></v-checkbox>
                              <v-text-field
                                class="ms-8"
                                ref="settings_delay"
                                type="number"
                                v-model="ultra_settings.send_delay"
                                outlined
                                dense
                                autocomplete="off"
                                hide-details
                                label="sendDelay"
                                @input="() => ultra_settings.send_delay = parseInt(ultra_settings.send_delay)"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="6">
                              <v-checkbox
                                ref="settings_ack"
                                v-model="ultra_settings.webhook_message_ack"
                                class="px-3 mt-4"
                                hide-details
                                label="webhook_message_ack"
                              ></v-checkbox>

                              <v-checkbox
                                ref="settings_media"
                                v-model="ultra_settings.webhook_message_download_media"
                                class="px-3 mt-4"
                                hide-details
                                label="webhook_message_download_media"
                              ></v-checkbox>

                              <v-checkbox
                                ref="settings_reaction"
                                v-model="ultra_settings.webhook_message_reaction"
                                class="px-3 mt-4"
                                hide-details
                                label="webhook_message_reaction"
                              ></v-checkbox>
                            </v-col>

                            <v-col cols="6">
                              <v-checkbox
                                ref="settings_received"
                                v-model="ultra_settings.webhook_message_received"
                                class="px-3 mt-4"
                                hide-details
                                label="webhook_message_received"
                              ></v-checkbox>

                              <v-checkbox
                                ref="settings_create"
                                v-model="ultra_settings.webhook_message_create"
                                class="px-3 mt-4"
                                hide-details
                                label="webhook_message_create"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-if="!Object.keys(ultra_settings).length && isUltraSettings">
                          <v-card-text class="mt-3">
                            <div v-if="editForm">
                              Aun no hay asociadas configuraciones para este canal, es posible que existan en Ultramsg, pero no esten aún en nuestra base de datos.
                              <a @click="getUltraSettings">presione aquí</a> para corroborar.
                              <v-progress-circular
                                  v-show="isUltraSettings && !settingsLoaded"
                                  class="ms-4"
                                  size="25"
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                            </div>
                            <ul v-else>
                              <li>Si quiere corroborar que la instancia ingresada posea alguna configuracion desde Ultramsg: <a @click="getUltraSettings">presione aqui</a>
                                <v-progress-circular
                                  v-show="isUltraSettings && !settingsLoaded"
                                  class="ms-4"
                                  size="25"
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </li>
                            </ul>
                            <div class="caption mt-2" style="color:red;">{{ settingsErrorMsg }}</div>
                          </v-card-text>
                        </div>

                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <v-col cols="12" md="12">

                <v-expansion-panels multiple :value="panelRespond" focusable>
                  <v-expansion-panel  >
                    <v-expansion-panel-header>Respond</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!-- Only if respond.io -->
                      <v-checkbox
                        v-if="this.getUserRole === 0"
                        ref="respond_sync"
                        v-model="channel_form.respond_sync"
                        class="px-3"
                        hide-details
                        label="Sincronizar canal con respond.io"
                      ></v-checkbox>

                      <v-col cols="12" md="12" v-if="channel_form.respond_sync">
                        <v-text-field
                          ref="RespondChannel"
                          v-model="respond_channel"
                          :rules="[v => !!v || 'Campo requerido']"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details
                          label="Respond.io Channel"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" v-if="channel_form.respond_sync">
                        <v-text-field
                          ref="RespondToken"
                          v-model="respond_token"
                          :rules="[v => !!v || 'Campo requerido']"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details
                          label="Respond.io APIToken"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" v-if="channel_form.respond_sync">
                        <v-text-field
                          ref="AccessToken"
                          v-model="access_token"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details
                          label="Respond.io Access Token"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" v-if="channel_form.respond_sync">
                        <v-checkbox
                          ref="contact_after_resp"
                          v-model="create_contact_after_resp"
                          class="px-3"
                          hide-details
                          label="Crear contacto en Respond sólo si se responde el mensaje"
                        ></v-checkbox>
                      </v-col>

                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

              </v-col>

            </v-row>

          </v-card-text>

          <v-card-text align="right" v-if="error_msg" class="mr-2 mt-2">
            <span class="caption" style="color:red;">
              {{ error_msg === 'error'
                  ? 'Ocurrió un error. Vuelva a intentar en unos minutos.'
                  : error_msg
              }}
            </span>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close()">
              CANCELAR
            </v-btn>
            <v-btn color="primary" type="submit">
              {{ editForm ? 'EDITAR CANAL' : 'CREAR CANAL' }}
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

  <script>
  // eslint-disable-next-line object-curly-newline
  import { mdiAccountOutline, mdiCellphone, mdiLockOutline, mdiMessageBulleted, mdiReloadAlert} from '@mdi/js'
  import { ref } from '@vue/composition-api'


  export default {
    data: () => {
      return {
        channel_form: {
          name: '',
          api_url: '',
          token: '',
          description: '',
          respond_sync: false,
          max_msg_daily: null,
          campaign_message: false,
          manual_message: false,
        },
        asociated_number: '',
        belongs_blacklist: false,
        editForm: null,
        panelUltra:[1],
        panelRespond: [1],
        isUltraSettings: false,
        settingsLoaded: true,
        ultra_settings: {
          // number: null,
          // send_delay: null,
          // webhook_url: null,
          // webhook_message_received: null,
          // webhook_message_create: null,
          // webhook_message_ack: null,
          // webhook_message_download_media: null,
          // webhook_message_reaction: null
        },
        respond_channel: '',
        respond_token: '',
        access_token: '',
        create_contact_after_resp: false,
        selectedCompany: [],
        search: null,
        error_msg: null,
        settingsErrorMsg: null,

      }
    },
    methods: {
      // createBlanckSettings() {
      //   this.ultra_settings.number = null
      //   this.ultra_settings.send_delay = null
      //   this.ultra_settings.webhook_message_ack = false
      //   this.ultra_settings.webhook_message_create = false
      //   this.ultra_settings.webhook_message_download_media = false
      //   this.ultra_settings.webhook_message_reaction = false
      //   this.ultra_settings.webhook_message_received = false
      //   this.ultra_settings.webhook_url = null

      //   this.settingsLoaded = true
      //   this.$forceUpdate()
      // },
      checkSettings() {
        if(Object.keys(this.ultra_settings).length >1) {
          this.settingsLoaded = true
        }
      },
      async getUltraSettings() {
        if(this.isUltraSettings){
          this.settingsLoaded = false

          let settings = {}
          try {
            const form = {
              url_instance: this.channel_form.api_url,
              token_instance: this.channel_form.token
            }
            const response = await this.$api.requestUltrainstanceSettings(form)

            if(response.error) {
              this.settingsErrorMsg = response.error
              console.log(this.settingsErrorMsg);
            }

            if(response.sendDelay) {
              console.log(response);
              settings = response

              this.ultra_settings.number = this.asociated_number
              this.ultra_settings.send_delay = settings.sendDelay
              this.ultra_settings.webhook_message_ack = settings.webhook_message_ack
              this.ultra_settings.webhook_message_create = settings.webhook_message_create
              this.ultra_settings.webhook_message_download_media = settings.webhook_message_download_media
              this.ultra_settings.webhook_message_reaction = settings.webhook_message_reaction
              this.ultra_settings.webhook_message_received = settings.webhook_message_received
              this.ultra_settings.webhook_url = settings.webhook_url

            }
            this.settingsLoaded = true;
          } catch (error) {

            const errorMessage = error.response.status === 500 ? "Revise que los datos tengan un formato correcto y correspondan a una instancia en Ultramsg'" : error.response.data[0] ;

            console.log(error.response);
            if (errorMessage.includes("Ocurrió un error en la petición a Ultramsg")) {

              const formattedErrorMessage = errorMessage.split(":")[0]+ ', revise los datos ingresados';
              this.settingsErrorMsg = formattedErrorMessage

            } else {
              this.settingsErrorMsg = errorMessage
            }
            this.settingsLoaded = true;
          }
        }
      },
      filter (item, queryText, itemText) {
        // console.log(item, queryText, itemText);
        // if (item.header) return false

        const hasValue = val => val != null ? val : ''

        const text = hasValue(item.name)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },


      open(item) {
        if (item) this.setItemToEdit(item)
        this.showingChannelModal = true
      },

      close() {
        this.resetForm()
        this.showingChannelModal = false
      },

      async setItemToEdit(id_to_edit) {
        const item = await this.$api.getChannel(id_to_edit.id)
        if (item) {
          this.editForm = item.id

          this.channel_form.max_msg_daily = item["max message daily"]
          this.channel_form.name = item.name
          this.channel_form.api_url = item.api_url
          this.channel_form.token = item.token
          this.channel_form.campaign_message = item.campaign_message
          this.channel_form.manual_message = item.manual_message
          this.channel_form.description = item.description
          this.channel_form.respond_sync = item.respond_sync? item.respond_sync : false
          this.selectedCompany = this.getAllCompaniesList.filter(company => item.companies.includes(company.id))
          this.asociated_number = item.number ?? ''
          this.belongs_blacklist = item.belongs_blacklist ? item.belongs_blacklist : false
          this.ultra_settings = item.settings ? item.settings : {}

          if (item.respond_sync) {
            this.respond_channel = item.respond_channel
            this.respond_token = item.respond_token
            this.access_token = item.access_token
            this.create_contact_after_resp = item.create_contact_after_resp
          }
        }
      },

      async createChannel() {
        //console.log(this.channel_form)
        if (this.$refs.channelForm.validate()) {
          const formParsed = JSON.parse(JSON.stringify(this.channel_form))

          if (this.channel_form.respond_sync) {
            formParsed['respond_channel'] = this.respond_channel
            formParsed['respond_token'] = this.respond_token
            formParsed['create_contact_after_resp'] = this.create_contact_after_resp

            if (this.access_token){
              formParsed['access_token'] = this.access_token
            }
          }

          if(this.ultra_settings && this.isUltraSettings) {
            formParsed['belongs_blacklist'] = this.belongs_blacklist
            formParsed['settings'] = this.ultra_settings
          }

          const companies_selected = []
          if (this.getUserRole === 0 && this.selectedCompany) {

            for (let i = 0; i < this.selectedCompany.length; i++) {
              if (this.selectedCompany[i].id) companies_selected.push(this.selectedCompany[i].id)
            }

            formParsed['company_associated_id'] = companies_selected
          }

          console.log(formParsed)

          try {
            const response = this.editForm
              ? await this.$api.updateChannel(this.editForm, formParsed)
              : await this.$api.createChannel(formParsed)
            this.$emit('reload-channel-list')
            this.close()
          } catch (e) {
            console.log(e)
            if (e?.response?.status === 403) {
              this.error_msg = e?.response?.data?.message
            } else {
              this.error_msg = 'error'
            }
            this.other_wpp_error = true
          }

        }

      },

      resetForm() {
        this.channel_form.api_url = ''
        this.channel_form.token = ''
        this.$refs.name.reset()
        this.$refs.max_msg_daily.reset()
        this.$refs.description.reset()
        this.selectedCompany = []
        this.error_msg = null
        this.editForm = null
        this.isUltraSettings = false
        this.settingsLoaded = true
        this.settingsErrorMsg = ''
        this.ultra_settings = {}
        this.asociated_number = '',
        this.belongs_blacklist = false
        this.panelUltra = [1]
        this.panelRespond = [1]
        this.channel_form.respond_sync = false
        if(this.channel_form.respond_sync) {
          if (this.respond_channel.length) this.respond_channel =''
          if (this.respond_token.length) this.respond_token = ''
          if (this.access_token.length) this.access_token = ''
          if (this.create_contact_after_resp) this.create_contact_after_resp = false
        }
      }
    },
    computed: {
      getUserRole() { return this.$store.getters['session/getUserRole']() },
      getAllCompaniesList() { return this.$store.getters['session/getAllCompaniesList']() },
      company_options() {
        const companies = this.getAllCompaniesList
        const companies_length = companies.length
        const parsedForVSelect = []
        for (let i = 0; i < companies_length; i++) {
          parsedForVSelect.push({
            text: companies[i].name,
            value: companies[i].id,
          })
        }
        return parsedForVSelect
      }
    },
    setup() {
      const max_msg_daily = ref(null)
      const api_url = ref('')
      //const description = ref('')
      const token = ref('')
      const showingChannelModal = ref(false)
      // const respond_sync = ref(false)
      const RespondChannel = ref('')
      const RespondToken = ref('')
      const AccessToken = ref('')

      return {
        showingChannelModal,
        max_msg_daily,
        api_url,
        token,
        // respond_sync,
        RespondChannel,
        RespondToken,
        AccessToken,
        prefix: 549,
        // icons
        icons: {
          mdiAccountOutline,
          mdiCellphone,
          mdiLockOutline,
          mdiMessageBulleted,
          mdiReloadAlert
        },
      }
    },
  }
  </script>
